<template>
    <div class="ApprovalInfo">
        <el-page-header @back="backPrivious" content="审批信息"></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <!-- 标题 -->
                <div class="banner">
                    {{ enterpriseStateInfo.enterpriseName }}
                </div>
                <!-- 搜索 -->
                <div class="f-c-b feature">
                    <div class="search_input">
                        <input type="text" placeholder="请输入手机号码搜索" v-model.trim="searchValue" @keyup.enter="search" />
                        <el-button class="search_icon" @click="search" icon="el-icon-search"></el-button>
                    </div>
                </div>
                <div class="list" v-if="approvalList && approvalList.length">
                    <div class="f-c item f-c" v-for="(item, index) of approvalList" :key="index">
                        <el-image class="item-image" :src="item.userAvatarUrl" fit="fill"></el-image>
                        <div class="f-c baseInfo" v-if="item.applyType == 'I'">
                            <span>{{ item.userName }} 邀请 {{ item.targetUserName }}加入</span>
                            <!-- <span>{{ item.createDate }}</span> -->
                        </div>
                        <div class="f-c baseInfo" v-else>
                            <span>{{ item.userName }}</span>
                            <span class="phone">{{ item.phone }}</span>
                            <!-- <span>{{ item.resultNote }}</span> -->
                            <!-- <span>{{ item.createDate }}</span> -->
                        </div>
                        <!-- 企业邀请 + 待处理W -->
                        <template v-if="item.applyType == 'I' && item.handleFlag == 'W'">
                            <div class="f-c-b state f1">
                                <div class="Info">
                                    <span class="state-text">待用户同意 </span>
                                    <span class="createDateText">·</span>
                                    <span class="createDateText"> {{ item.createDate }}</span>
                                </div>
                                <div class="f-c-e btnBox">
                                    <div class="btnstyle agreeBtn" @click="cancelInvitation(item.applyId)">
                                        撤销邀请
                                    </div>
                                </div>
                                <!-- <el-button type="primary" class="w-120 mt-15 alignSelf-end f1" round
                                    @click="cancelInvitation(item.applyId)">撤销邀请</el-button> -->
                            </div>
                        </template>
                        <!-- 企业邀请 + 已处理S -->
                        <template v-if="item.applyType == 'I' && item.handleFlag == 'S'">
                            <div class="f-c-b state f1" v-if="item.result == 'N'">
                                <div class="Info">
                                    <span class="state-text" :style="{'color': item.applyNote == '已拒绝' ? 'red' : ''}">{{ item.applyNote }} </span>
                                    <span class="createDateText">·</span>
                                    <span class="createDateText"> {{ item.createDate }}</span>
                                </div>
                                <div class="f-c-e btnBox">
                                    <div class="btnstyle agreeBtn" @click="delectInfo(item.applyId)">
                                        清除消息
                                    </div>
                                </div>
                                <!-- <div class="state-text f1">{{ item.applyNote }}</div> -->
                                <!-- <el-button type="primary" class="w-120 alignSelf-end mt-15" round
                                    @click="delectInfo(item.applyId)">清除消息</el-button> -->
                            </div>
                        </template>
                        <!-- 用户申请加入 + 未处理W -->
                        <template v-if="item.applyType == 'J' && item.handleFlag == 'W'">
                            <div class="f-c-b state f1">
                                <div class="Info">
                                    <span class="state-text">待处理 </span>
                                    <span class="createDateText">·</span>
                                    <span class="createDateText"> {{ item.createDate }}</span>
                                </div>

                                <div class="f-c-b btnBox">
                                    <div class="btnstyle agreeBtn"
                                        @click="enterpriseApprovalPass(item.applyId, item.applyType)">
                                        同意
                                    </div>
                                    <div class="btnstyle refuseBtn"
                                        @click="enterpriseApprovalDisagree(item.applyId, item.applyType)">
                                        拒绝
                                    </div>
                                </div>
                            </div>
                        </template>
                        <!-- 用户申请退出 + 未处理W -->
                        <template v-if="item.applyType == 'Q' && item.handleFlag == 'W'">
                            <div class="f-cl state f1">
                                <div class="state-text f1">待处理</div>
                                <div class="f-c-e alignSelf-end mt-15">
                                    <el-button type="success" round class="w-120"
                                        @click="enterpriseApprovalPass(item.applyId, item.applyType)">同意</el-button>
                                    <el-button type="danger" round class="w-120"
                                        @click="enterpriseApprovalDisagree(item.applyId, item.applyType)">拒绝</el-button>
                                </div>
                            </div>
                        </template>
                    </div>
                    <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
                        @pagination="fetchData" />
                </div>
                <el-empty v-else description="暂无审批信息！"></el-empty>
            </div>
        </div>
        <!-- <div class="f-c-b">
            <el-input v-model.trim="searchValue" placeholder="请输入手机号码搜索" prefix-icon="el-icon-search"
                @keyup.enter.native="search" clearable @clear="handleClear">
            </el-input>
            <el-button class="w--15 ml-40" round type="primary" @click="search">搜索</el-button>
        </div> -->

    </div>
</template>

<script>
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin'
export default {
    inject: ["reload"],
    name: "ApprovalInfo",
    mixins: [PaginationRetention],
    components: {
        NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue')
    },
    data() {
        return {
            searchValue: "",
            approvalList: [],//审批列表
            userName: '',
            enterpriseStateInfo: ''
        };
    },
    created() {
        this.userName = this.$getStorage('userName');
        this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo')
    },
    methods: {
        getDate() {
            this.enterpriseApproval(this.paging, this.pagingSize, this.searchValue)
        },
        // 企业审批人员列表接口
        enterpriseApproval(page = this.page, size = this.size, phone = this.searchValue) {
            let that = this;
            that.$http.enterpriseApproval({
                applyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                page,
                size,
                phone
            }).then(res => {
                if (res.code == 200) {
                    that.approvalList = res.data.records
                    that.currentPage = res.data.current;//当前页数
                    that.total = res.data.total//总页数
                }
            })
        },
        // 搜索企业
        search() {
            // if (!this.searchValue) {
            //     return this.$common.message('请输入手机号码搜索！', 'warning', 1500)
            // }
            this.approvalList = []
            this.enterpriseApproval(1, 10, this.searchValue)
        },
        handleClear() {
            this.approvalList = []
            this.enterpriseApproval(1, 10, this.searchValue)
        },
        // 企业审批人员通过接口
        enterpriseApprovalPass(applyId, applyType) {
            let that = this;
            that.$confirm('同意', '提示').then(() => {
                that.$http.enterpriseApprovalPass({
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    applyId,
                    applyType
                }).then(res => {
                    that.$common.message(res.msg, 'success')
                    that.reload();
                })
            }).catch(() => { })
        },
        // 用户拒绝加入企业接口
        enterpriseApprovalDisagree(applyId, applyType) {
            let that = this;
            that.$confirm('拒绝', '提示').then(() => {
                that.$http.enterpriseApprovalDisagree({
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    applyId,
                    applyType
                }).then(res => {
                    that.$common.message(res.msg, 'success')
                    that.reload();
                })
            }).catch(() => { })
        },
        // 企业撤销邀请用户接口
        cancelInvitation(applyId) {
            let that = this;
            that.$confirm('确定撤销邀请?', '提示').then(() => {
                that.$http.enterpriseRevokeJoinUser({
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    applyId,
                }).then(res => {
                    that.$common.message(res.msg, 'success')
                    that.reload();
                })
            }).catch(() => { })
        },
        // 企业审批人员清空指定消息
        delectInfo(applyId) {
            let that = this;
            that.$confirm('确定删除该条消息?', '提示').then(() => {
                that.$http.enterpriseApprovalEmptyOne({
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    applyId
                }).then(res => {
                    that.$common.message(res.msg, 'success')
                    that.reload();
                })
            }).catch(() => { })
        },
    },
}

</script>
<style lang='less' scoped>
.ApprovalInfo {
    .main {
        width: 980px;

        .banner {
            background: url('../../../assets/supply/supplyBanner.png');
            height: 100px;
            color: #FFFFFF;
            font-size: 30px;
            line-height: 100px;
            text-align: center;
            margin-bottom: 40px;
        }

        .feature {
            margin-bottom: 20px;

            .search_input {
                input {
                    background-color: #FFF;
                    border-radius: 10px 0 0 10px;
                    border: 1px solid #E0E1E3;
                    box-sizing: border-box;
                    color: #606266;
                    display: inline-block;
                    height: 50px;
                    line-height: 50px;
                    outline: 0;
                    padding: 0 20px;
                    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
                    width: 400px;
                    border-right: none;
                    font-size: 16px;
                }

                .search_icon {
                    width: 80px;
                    height: 50px;
                    border-top-right-radius: 10px !important;
                    border-top-left-radius: 0px !important;
                    border-bottom-right-radius: 10px !important;
                    border-bottom-left-radius: 0px !important;
                    color: #FFFFFF;
                    background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
                }
            }
        }

        .list {
            .item {
                padding: 20px 30px 20px 30px;
                background: #F8F8F8;
                border-radius: 10px;
                height: 60px;
                margin-bottom: 20px;

                .item-image {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    margin-right: 20px;
                }

                .baseInfo {
                    margin-right: 40px;
                    .phone {
                        margin-left: 20px;
                        
                    }

                    // span{
                    //     margin-right: 20px;
                    // }
                    // span:first-child {
                    //     // margin-bottom: 20px;
                    // }
                }

                .state {
                    text-align: right;
                    // border: 1px solid red;

                    .state-text {
                        color: #FFA300;
                    }

                    .createDateText {
                        color: #999999;
                    }

                    .btnBox {
                        width: 250px;

                        .btnstyle {
                            cursor: pointer;
                            border-radius: 5px;
                            width: 120px;
                            height: 40px;
                            text-align: center;
                            line-height: 40px;
                        }

                        .agreeBtn {
                            color: #FFF;
                            background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
                        }

                        .refuseBtn {
                            border: 1px solid #1785F6;
                            color: #016EF2;
                            background: #FFFFFF;
                        }
                    }
                }
            }
        }
    }

    // .list {
    //     margin-top: 30px;

    //     .item {
    //         padding: 10px;
    //         border-bottom: 1px solid #ddd;

    //         .item-image {
    //             width: 100px;
    //             height: 100px;
    //             border-radius: 50%;
    //         }

    //         .baseInfo {
    //             margin-left: 30px;

    //             span:first-child {
    //                 margin-bottom: 20px;
    //             }
    //         }

    //         .state {
    //             text-align: right;

    //             .state-text {
    //                 margin-right: 30px;
    //                 color: red;
    //             }
    //         }
    //     }

    //     .item:hover {
    //         background-color: rgb(233, 250, 255);
    //         cursor: pointer;
    //     }
    // }
}
</style>
